import Auth from '@/models/auth';
import jwt_decode from "jwt-decode";

export default class User extends Auth {
    constructor() {
        super();

        this.name           = null;
        this.memberNumber   = null;
        this.everLinkedCard = false;
        this.activeLinkedPaymentCards = false;
        this.cardlessTransactions = false;
        this.resendableCardTransfers = false;
        this.failedBankTransfersOnActiveBankAccount = false;
        this.seeBankAccountsSection = false;
        this.activeBankAccounts = 0;
    }

    getUser() {
        let jwtTokenDecoded = jwt_decode(this.getTokenRaw());
        this.setMemberNumber(jwtTokenDecoded.external_id);
    }

    setName(name) {
        this.name = name;
    }

    setMemberNumber(memberNumber) {
        this.memberNumber = memberNumber;
    }

    setActiveLinkedPaymentCards(state) {
        this.activeLinkedPaymentCards = state;
    }

    setEverLinkedCard(state) {
        this.everLinkedCard = state;
    }

    setCardlessTransactions(state) {
        this.cardlessTransactions = state;
    }

    setHasResendableCardTransfers(state) {
        this.resendableCardTransfers = state;
    }

    setHasFailedBankTransfersOnActiveBankAccount(state) {
        this.failedBankTransfersOnActiveBankAccount = state;
    }

    setCanSeeBankAccountsSection(state) {
        this.seeBankAccountsSection = state;
    }

    setActiveBankAccounts(state) {
        this.activeBankAccounts = state;
    }

    getName() {
        return this.name;
    }

    getMemberNumber() {
        return this.memberNumber;
    }

    hasActiveLinkedPaymentCards() {
        return this.activeLinkedPaymentCards;
    }

    hasEverLinkedCard() {
        return this.everLinkedCard;
    }

    hasCardlessTransactions() {
        return this.cardlessTransactions;
    }

    hasResendableCardTransfers() {
        return this.resendableCardTransfers;
    }

    hasFailedBankTransfersOnActiveBankAccount() {
        return this.failedBankTransfersOnActiveBankAccount;
    }

    canSeeBankAccountsSection() {
        return this.seeBankAccountsSection;
    }

    getActiveBankAccounts() {
        return this.activeBankAccounts;
    }

    hasActiveBankAccount() {
        return this.activeBankAccounts > 0;
    }

    resetName() {
        this.name = null;
    }

    resetMemberNumber() {
        this.memberNumber = null;
    }

    resetEverLinkedCard() {
        this.setEverLinkedCard(false)
    }

    resetCardlessTransactions() {
        this.setCardlessTransactions(false)
    }

    resetHasResendableCardTransfers() {
        this.setHasResendableCardTransfers(false)
    }

    resetHasFailedBankTransfersOnActiveBankAccount() {
        this.hasFailedBankTransfersOnActiveBankAccount(false)
    }

    resetCanSeeBankAccountsSection() {
        this.setCanSeeBankAccountsSection(false)
    }

    resetActiveBankAccounts() {
        this.setCanSeeBankAccountsSection(0)
    }

    killUser() {
        this.resetName();
        this.resetMemberNumber();
        this.resetEverLinkedCard();
        this.resetCardlessTransactions();
        this.resetHasResendableCardTransfers();
        this.resetHasFailedBankTransfersOnActiveBankAccount();
        this.resetCanSeeBankAccountsSection();
        this.resetActiveBankAccounts();
    }
}
