<template>
  <alert-global-template :label="label" :type="type" @onClose="reset"/>
</template>

<script>
  import AlertGlobalTemplate from '@/components/ui/alert/template/Default';

  export default {
    name: "AlertGlobal",

    components: {
      AlertGlobalTemplate
    },

    data: function() {
      return {
        label: null,
        type: null,
      }
    },

    methods: {
      reset() {
        this.label = null;
        this.type  = null;
      },

      scroll() {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },

      setSuccess(label) {
        this.scroll();
        this.reset();

        this.label = label;
        this.type  = 'success';
      },

      setError(label) {
        this.scroll();
        this.reset();

        this.label = label;
        this.type  = 'error';

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },

      closeAlert() {
        this.label = null;
      }
    },

    created: function() {
      this.$root.$on('ls::global-alert::reset', () => {
        this.reset();
      });

      this.$root.$on('ls::global-alert::success', (label) => {
        this.setSuccess(label);
      });

      this.$root.$on('ls::global-alert::error', (label) => {
        this.setError(label);
      });
    },

    watch: {
      $route() {
        this.closeAlert();
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
