<template>
  <div>
    <footer-top/>

    <footer aria-label="Footer">
      <div class="footer-inner">
        <b-container>
          <b-row>
            <b-col sm="2" md="12" lg="12">
            <img class="fdm-logo" src="@/assets/images/fdm/logo.svg" alt="FDM logo">
            </b-col>
            <b-col sm="6" md="6" lg="3">
              <div class="items-wrapper">
                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group1_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in organization" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>

                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group2_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in products" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>

                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group3_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in partners" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>

            <b-col sm="6" md="6" lg="3">
              <div class="items-wrapper">
                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group4_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in press" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>

                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group5_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in jobs" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>

                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group6_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in terms" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>

            <b-col sm="6" md="6" lg="3">
              <div class="items-wrapper">
                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group7_title1') }}</h2>

                  {{ $translations.getTranslation('footer_group7_link_label1_part1') }}<br>
                  {{ $translations.getTranslation('footer_group7_link_label1_part2') }}<br>

                  <ul>
                    <li><link-item href="tel:+4570133040" target="_self" :label="$translations.getTranslation('footer_group7_link_label2')"/></li>
                    <li><link-item href="mailto:kontaktfdm@fdm.dk" target="_self" :label="$translations.getTranslation('footer_group7_link_label3')"/></li>
                    <li><link-item href="https://fdm.dk" :label="$translations.getTranslation('footer_group7_link_label4')" :externalLink="true"/></li>
                  </ul>
                </div>

                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group8_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in addresses" :key="index">
                      <link-item :href="item.href" :label="item.label" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>

            <b-col sm="6" md="6" lg="3">
              <div class="items-wrapper">
                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group9_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in follow" :key="index">
                      <link-item :href="item.href" :label="item.label" :classes="item.classes" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>

                <div class="item">
                  <h2>{{ $translations.getTranslation('footer_group10_title1') }}</h2>

                  <ul>
                    <li v-for="(item, index) in contact" :key="index">
                      <link-item :href="item.href" :label="item.label" :classes="item.classes" :target="item.target" :externalLink="item.externalLink"/>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <div class="accessibility-disclaimer">
          <p><b-icon icon="box-arrow-up-right"></b-icon>{{ $translations.getTranslation('footer_accessibility_text1') }}</p>

          <!-- Only visible for screen readers -->
          <p id="accessibility-external-link-disclaimer" class="sr-only">
            {{ $translations.getTranslation('footer_accessibility_sr_only_text1') }}
          </p>
        </div>

        <div class="copyrights-reserved">
          <link-item href="https://www.loyalsolutions.eu/copyrights" target="_blank" :labelHtml="getFooterCopyrightsReservedLabel()" :externalLink="true"/>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import FooterTop from '@/components/layout/footer/components/FooterTop';
  import LinkItem from '@/components/layout/footer/components/LinkItem';

  export default {
    name: 'AppFooter',

    components: {
      LinkItem, FooterTop,
    },

    data: function () {
      return {
        organization: [],
        products: [],
        partners: [],
        press: [],
        jobs: [],
        terms: [],
        addresses: [],
        follow: [],
        contact: []
      }
    },

    methods: {
      getFooterCopyrightsReservedLabel() {
        return `&copy; ${this.$moment().format('YYYY')} ${this.$translations.getTranslation('footer_copyrights_reserved_label')}`;
      },

      setFooterLinks() {
        this.organization = [
          { href: 'https://fdm.dk/om-fdm', label: this.$translations.getTranslation('footer_group1_link_label1'), externalLink: true, }
        ];

        this.products = [
          { href: 'https://fdm.dk/vi-tilbyder/bilsyn', label: this.$translations.getTranslation('footer_group2_link_label1'), externalLink: true, },
          { href: 'https://fdm.dk/vi-tilbyder/biltest', label: this.$translations.getTranslation('footer_group2_link_label2'), externalLink: true, },
          { href: 'https://fdm.dk/vi-tilbyder/vejhjaelp', label: this.$translations.getTranslation('footer_group2_link_label3'), externalLink: true, }
        ];

        this.partners = [
          { href: 'https://fdm.dk/vi-tilbyder/forsikring', label: this.$translations.getTranslation('footer_group3_link_label1'), externalLink: true, },
          { href: 'https://www.fdm-travel.dk/', label: this.$translations.getTranslation('footer_group3_link_label2'), externalLink: true, }
        ];

        this.press = [
          { href: 'https://fdm.dk/om-fdm/pressekontakt', label: this.$translations.getTranslation('footer_group4_link_label1'), externalLink: true, }
        ];

        this.jobs = [
          { href: 'https://fdm.dk/om-fdm/job-karriere-fdm', label: this.$translations.getTranslation('footer_group5_link_label1'), externalLink: true, }
        ];

        this.terms = [
          { href: 'https://fdm.dk/om-fdm/persondatapolitik', label: this.$translations.getTranslation('footer_group6_link_label1'), externalLink: true, },
          { href: 'https://fdm.dk/om-fdm/persondatapolitik', label: this.$translations.getTranslation('footer_group6_link_label2'), externalLink: true, },
          { href: 'https://fdm.dk/om-fdm/ophavsret', label: this.$translations.getTranslation('footer_group6_link_label3'), externalLink: true, },
          { href: 'https://fdm.dk/om-fdm/fdms-forretningsbetingelser', label: this.$translations.getTranslation('footer_group6_link_label4'), externalLink: true, }
        ];

        this.addresses = [
          { href: 'https://fdm.dk/vi-tilbyder/bilsyn/fdms-synshaller', label: this.$translations.getTranslation('footer_group8_link_label1'), externalLink: true, },
          { href: 'https://fdm.dk/vi-tilbyder/kob-leasing/nummerplade-ejerskifte/motorkontorer', label: this.$translations.getTranslation('footer_group8_link_label2'), externalLink: true, },
          { href: 'https://fdmshop.dk/butik-og-aabningstider/', label: this.$translations.getTranslation('footer_group8_link_label3'), externalLink: true, },
          { href: 'https://fdm.dk/vi-tilbyder/fdm-koreteknik-events-motorsport', label: this.$translations.getTranslation('footer_group8_link_label4'), externalLink: true, },
          { href: 'http://www.fdm-travel.dk/kontakt-os/', label: this.$translations.getTranslation('footer_group8_link_label5'), externalLink: true, },
        ];

        this.follow = [
          { href: 'https://twitter.com/fdmdk', label: this.$translations.getTranslation('footer_group9_link_label1'), classes: '', externalLink: true, },
          { href: 'https://www.facebook.com/FDM.dk/', label: this.$translations.getTranslation('footer_group9_link_label2'), classes: '', externalLink: true, },
          { href: 'https://www.instagram.com/fdm_motor/', label: this.$translations.getTranslation('footer_group9_link_label3'), classes: '', externalLink: true, },
          { href: 'https://www.linkedin.com/company/fdm', label: this.$translations.getTranslation('footer_group9_link_label4'), classes: '', externalLink: true, },
          { href: 'https://fdm.dk/rssfeeds/1', label: this.$translations.getTranslation('footer_group9_link_label5'), classes: '', externalLink: true, }
        ];

        this.contact = [
          { href: 'tel:+4570133040', label: this.$translations.getTranslation('footer_group10_link_label1'), classes: '', target: '_self'},
          { href: 'https://fdm.dk/om-fdm/kontakt', label: this.$translations.getTranslation('footer_group10_link_label2'), classes: '', externalLink: true, },
          { href: 'https://fdm.dk/om-fdm/kontakt', label: this.$translations.getTranslation('footer_group10_link_label3'), classes: '', externalLink: true, },
        ];
      }
    },

    mounted() {
      this.setFooterLinks();
    },

    watch: {
      '$root.translationsClass.translations': function () {
        this.setFooterLinks();
      },
    }
  }
</script>

<style scoped lang="scss">
  footer {
    width: 100%;
    float: left;
    padding: 4rem 0;
    margin-bottom: 0;
    font-family: "lato-bold", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif !important;
    .fdm-logo {
      max-width: 150px;
      padding-bottom: 90px;
    }
    
    .items-wrapper ul li a {
      color: #002aa5;
      text-decoration: none;
      line-height: 24px;

      &:hover {
        color: #002aa5;
        border-bottom: 2px solid #002aa5;
      }
    }

    .accessibility-disclaimer, .copyrights-reserved {
      width: 100%;
      margin-top: 30px;
      font-family: "lato-bold", Helvetice Neue, arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      -webkit-font-smoothing: subpixel-antialiased;
      padding: 0 8px;

      svg {
        margin-right: 5px;
        font-size: 15px;
      }
    }

    .footer-inner {
      width: 100%;
      max-width: $appWidth;
      margin: auto;

      .items-wrapper {
       .item {
         font-family: "lato-bold", Helvetice Neue, arial, sans-serif;
         font-size: 16px;
         font-weight: 500;
         line-height: 24px;
         -webkit-font-smoothing: subpixel-antialiased;
         margin: 32px 0;

         &:first-child {
           margin-top: 0;

           h2 {
             margin-top: 0;
           }
         }

         &:last-child {
           margin-bottom: 0;
         }

         &:after {
           content: "";
           display: block;
           width: 50%;
           border-bottom: 1px solid hsla(0, 0%, 100%, .5);
           margin-top: 2rem;
         }


         h2 {
           font-size: 14px;
           line-height: 24px;
           font-family: "lato-bold", Helvetice Neue, arial, sans-serif;
           margin: 8px 0;
         }
       }
      }
    }

    @media (min-width: $mdBreakpoint) {
      .row > div:nth-child(3), .row > div:nth-child(4) {
        .item:first-child {
          margin-top: 32px;
        }
      }
    }

    @media (min-width: $lgBreakpoint) {
      .footer-inner {
        .items-wrapper {
          .item {
            h2 {
              margin-top: 0;
            }
          }
        }
      }

      .row > div:nth-child(3), .row > div:nth-child(4) {
        .item:first-child {
          margin-top: 0;
        }
      }
    }

    /* START Control border*/
    .row > div:last-child {
      .item:last-child {
        &:after {
          border: none;
        }
      }
    }

    @media (min-width: 570px) {
      .row > div:nth-child(3) {
        .item:last-child {
          &:after {
            border: none;
          }
        }
      }
    }

    @media (min-width: 992px) {
      .row > div {
        .item:last-child {
          &:after {
            border: none;
          }
        }
      }
    }
    /* END Control border*/

    @media (min-width: 1474px) {
      .accessibility-disclaimer, .copyrights-reserved {
        padding: 0;
      }
    }

    // Text Colors
    * {
      color: #002aa5;
    }

    h2 {
      color: #b0bbc2;
      font-size: 0.5rem;
    }
  }
</style>
