<template>
  <header aria-label="Header">
    <div class="header-inner">

      <b-navbar toggleable="md" :class="{'nav-is-collapsed': toggleState}">
        <b-navbar-brand href="https://fdm.dk/" target="_blank">
            <img src="@/assets/images/fdm/logo.svg" alt="FDM logo">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" @click="onNavButtonClick">
          <v-burger
            :active="toggleState"
            @updated="isActive = $event"
            :type="type"
            role="menu"
            :burgerStyle="burgerStyle">
          </v-burger>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :href="getRoutePath('LinkCard')" @click.prevent="goToRoute('LinkCard')"
                        v-if="!$user.isAuth() || ($user.isAuth() &&
                        (!$user.hasEverLinkedCard() && !$user.hasCardlessTransactions())) ||
                        ($user.isAuth() && isRoute('ManageCards'))">

              <img src="@/assets/images/header/cards-white.png" width="23" height="17" alt="Icon" class="icon-cards mobile-version" role="presentation">
              <img src="@/assets/images/header/cards.png" width="23" height="17" alt="Icon" class="icon-cards desktop-version" role="presentation">
              <span>{{ $translations.getTranslation('header_nav_item1_label') }}</span>
            </b-nav-item>

            <b-nav-item :href="getRoutePath('ManageCards')" @click.prevent="goToRoute('ManageCards')"
                        v-if="$user.isAuth() && ($user.hasEverLinkedCard() || $user.hasCardlessTransactions())
                        && !isRoute('ManageCards')">

              <img src="@/assets/images/header/cards-white.png" width="23" height="17" alt="Icon" class="icon-cards mobile-version" role="presentation">
              <img src="@/assets/images/header/cards.png" width="23" height="17" alt="icon" class="icon-cards desktop-version" role="presentation">
              <span>{{ $translations.getTranslation('header_nav_item2_label') }}</span>
            </b-nav-item>

            <b-nav-item :href="getRoutePath('LinkCard')" @click.prevent="login()" v-if="!$user.isAuth()">
              <img src="@/assets/images/header/login-white.png" width="15" height="15" alt="Icon" class="icon-login mobile-version" role="presentation">
              <img src="@/assets/images/header/login.png" width="15" height="15" alt="Icon" class="icon-login desktop-version" role="presentation">
              <span>{{ $translations.getTranslation('header_nav_item3_label') }}</span>
            </b-nav-item>

            <b-nav-item href="#" v-if="$user.isAuth()" @click.prevent="logout">
              <img src="@/assets/images/header/login-white.png" width="15" height="15" alt="Icon" class="icon-login mobile-version" role="presentation">
              <img src="@/assets/images/header/login.png" width="15" height="15" alt="Icon" class="icon-login desktop-version" role="presentation">
              <span>{{ $translations.getTranslation('header_nav_item4_label') }}</span>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

    </div>
  </header>
</template>

<script>
  import {mixinRoutesHandler} from '@/mixins/routesHandler';
  import {mixinWindowResizeListener} from '@/mixins/windowResizeListener';

  // Models
  import LogoutApi from "@/models/logoutApi";

  export default {
    name: 'AppFooter',

    mixins: [mixinRoutesHandler, mixinWindowResizeListener],

    data: function () {
      return {
        toggleState: false,
        type: 'collapse',
        burgerStyle: {
        }
      }
    },

    methods: {
      isRoute(routeName) {
        return this.$route.name === routeName;
      },

      login() {
        if (this.$route.name !== 'LinkCard') {
          this.$router.push({
            name: 'LinkCard',
          });
        } else {
          this.$root.$emit('bv::show::modal', 'modal-login');
        }
      },

      logout() {
        let _this = this;
        new LogoutApi({}, {}).post(function () {
          _this.resetToggleState();

          _this.$user.killAuth();

          if (_this.$route.name !== 'LinkCard') {
            _this.$router.push({
              name: 'LinkCard',
            });
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });

            _this.$root.$emit('bv::show::modal', 'modal-login');
          }
        });
      },

      goToRoute(routeName) {
        this.resetToggleState();
        this._mixinGoToRouteName(routeName);
      },

      getRoutePath(routeName) {
        return this._mixinGetRoutePath(routeName);
      },

      onNavButtonClick() {
        this.updateToggleState();
      },

      updateToggleState() {
        this.toggleState = !this.toggleState;
      },

      resetToggleState() {
        this.toggleState = false;
      },

      toggleClassOnBody() {
        let element = document.getElementsByTagName("body")[0];

        if (this.toggleState) {
          element.classList.add("nav-is-collapsed");
        } else {
          element.classList.remove("nav-is-collapsed");
        }
      },

      onWindowResize(width) {
        if (width >= 768) {
          this.resetToggleState();
        }
      }
    },

    watch: {
      'toggleState': function () {
        this.toggleClassOnBody();
      },

      'mixinWindowResizeListenerData.width': function (newVal) {
        this.onWindowResize(newVal);
      },
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep.hamburger {
    padding: 0;
    width: 32px;
    height: 46px;

    .hamburger-box {
      width: 32px;
      height: 27px;

      .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
        width: 32px;
        height: 5px;
      }
    }
  }

  header {
    width: 100%;
    border-bottom: 3px solid #3ba9dc;
    height: 59px;
    display: flex;
    align-items: center;

    .header-inner {
      width: 100%;
      max-width: $appWidth;

      nav {
        background-color: white;
        padding: 8px 8px;
        height: 56px;
        -webkit-transition: background-color 1000ms ease;
        -ms-transition: background-color 1000ms ease;
        transition: background-color 1000ms ease;

        &.nav-is-collapsed {
          background-color: #3ba9dc;
          -webkit-transition: background-color 100ms ease;
          -ms-transition: background-color 100ms ease;
          transition: background-color 100ms ease;
        }

        .navbar-brand {
          padding: 0;

          img {
            height: 32px;
            vertical-align: baseline;
          }
        }

        .navbar-toggler {
          border: none;
          padding: 0;
        }

        .navbar-collapse {
          position: absolute;
          width: calc(100% + 17px);
          z-index: 1;
          top: 56px;
          left: 0;

          ul {
            width: 100%;
            background-color: #3ba9dc;
            height: 100vh;
            padding-bottom: 100px;

            font-size: 22px;
            line-height: 26px;
            font-family: "merriweather-regular", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
            overflow-y: scroll;

            li {
              padding: 16px;

              &:hover {
                text-decoration: underline;
              }

              a {
                color: white;

                &:hover {
                  color: white;
                }

                img {
                  margin-right: 10px;

                  &.desktop-version {
                    display: none;
                  }
                }
              }

              &:last-child {
                a {
                  img {
                    margin-right: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 767px) {
      .header-inner {
        nav {
          .navbar-brand {
            img {
              vertical-align: middle;
            }
          }
        }
      }
    }

    @media (min-width: $mdBreakpoint) {
      .header-inner {
        margin: auto;

        nav {
          background-color: white;
          padding: 8px 8px;
          height: 56px;

          .navbar-brand {
            padding: 0;

            img {
              height: 32px;
            }
          }

          .navbar-toggler {
            border: none
          }

          .navbar-collapse {
            position: static;
            width: 100%;
            z-index: 1;
            top: 0;
            left: 0;

            ul {
              background-color: white;
              height: auto;
              padding-bottom: 0;

              font-family: "mada", Helvetice Neue, arial, sans-serif;
              font-size: 14px;
              line-height: 25px;
              overflow-y: hidden;

              display: flex;
              justify-content: flex-end;

              li {
                padding: 0;

                &:hover {
                  text-decoration: none;
                }

                a {
                  color: black;

                  &:hover {
                    color: #3ba9dc;
                  }

                  img {
                    margin-right: 5px;

                    &.desktop-version {
                      display: inline-block;
                    }

                    &.mobile-version {
                      display: none;
                    }
                  }
                }

                &:last-child {
                  a {
                    padding-right: 0;

                    img {
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

    @media (min-width: $lgBreakpoint) {
      height: 99px;
      .header-inner {
        nav {
          .navbar-brand {
            img {
              height: 40px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1475px) {
      .header-inner {
        nav {
          padding: 0 0;
        }
      }
    }
  }
</style>
