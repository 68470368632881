<template>
  <b-modal id="modal-link-bank-account-monthly-notice" hide-footer centered no-close-on-esc no-close-on-backdrop hide-header-close>
    <template v-slot:modal-header="">
      <img src="@/assets/images/fdm/logo.svg" alt="FDM logo">
    </template>

    <p class="paragraph-margin-bottom">
      {{ $translations.getTranslation('modal_link_bank_account_monthly_notice') }}
    </p>

    <div class="button-wrapper">
      <button-primary @click.native="navigateToManageCards()">
        {{ $translations.getTranslation('modal_link_bank_account_ok') }}
      </button-primary>

      <button-secondary @click.native="hideModal()">
        {{ $translations.getTranslation('modal_link_bank_account_cancel') }}
      </button-secondary>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";

// Buttons
import ButtonPrimary from '@/components/ui/button/Primary';
import ButtonSecondary from '@/components/ui/button/Secondary';

export default {
  name: 'ModalLinkBankAccountMonthlyNotice',

  props: {
    data: {
      default: null,
      type: Object
    }
  },

  components: {
    ButtonPrimary, ButtonSecondary
  },

  created() {
    this.$root.$on('ls::show::modal', (value) => {
      if (value === 'modal-link-bank-account-monthly-notice' && this.$user.isAuth() && this.isModalDueToShow()) {
        this.showModal();
      }
    });
  },

  methods: {
    isModalDueToShow() {
      const currentMonthKey = moment().format('YYYY-MM');
      const lastReminderMonth  = localStorage.getItem('ls.accountNotice.lastShownMonth');

      const hasActiveBankAccount = this.$user.hasActiveBankAccount();
      const hasResendableCardTransfers = this.$user.hasResendableCardTransfers();
      const hasBeenShownRecently = lastReminderMonth === currentMonthKey;
      const hasActiveCardLinked =  this.$user.hasActiveLinkedPaymentCards();

      // Determine whether the modal should be shown:
      //  - hasBeenShownRecently: Ensures the notice is displayed only once per month.
      //  - hasResendableCardTransfers: If true, another modal (ModalLinkBankAccountNotice) is shown, so we skip this one.
      //  - hasActiveBankAccount: If true, the user already has a linked bank account, so this notice is unnecessary.
      //  - hasActiveCardLinked: Ensures the user has a linked payment card before showing the notice.
      return !hasBeenShownRecently && !hasActiveBankAccount && !hasResendableCardTransfers && hasActiveCardLinked;
    },

    updateLastReminderMonth() {
      const currentYearMonthKey = moment().format('YYYY-MM');
      localStorage.setItem('ls.accountNotice.lastShownMonth', currentYearMonthKey);
    },

    navigateToManageCards() {
      this.$router.push({
        name: 'ManageCards'
      });

      this.hideModal();
    },

    showModal() {
      this.$bvModal.show('modal-link-bank-account-monthly-notice');
    },

    hideModal() {
      this.$bvModal.hide('modal-link-bank-account-monthly-notice');
      this.updateLastReminderMonth();
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/
#modal-link-bank-account-monthly-notice {
  border-radius: 0;
  min-height: 250px;
  padding: 0 !important;

  .paragraph-margin-bottom {
    margin-bottom: 55px;
  }

  .modal-header {
    img {
      width: 114px;
      display: block;
      margin: auto;
    }
  }

  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin: auto;

    button {
      margin-bottom: 16px;
    }
  }

  table {
    font-size: 14px;
    margin-bottom: 0;
    table-layout: fixed;

    thead {
      display: none;
    }

    tbody tr td {
      padding: 2px;
      border: none;
    }

    tbody {
      border-bottom: 1px solid grey;
    }

    tbody tr {
      td {
        padding-top: 10px;

        &:first-child {
          width: 100px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>